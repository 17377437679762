'use client'

import { RiMenu2Line } from '@remixicon/react'
import { Button, Drawer, Grid } from 'antd'
import Image from 'next/image'
import Link from 'next/link'
import { useSearchParams } from 'next/navigation'
import companyLogo from 'public/companyLogo.png'
import React, { PropsWithChildren, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useDrawerState from '@/hooks/context/useDrawerState'
import useThemeState from '@/hooks/context/useThemeState'
import useTourState from '@/hooks/context/useTourState'
import useAuth from '@/hooks/useAuth'

import { colors, logoIndexClx, showIndexHeaderText } from '@/branding-config'
import { COMPANY } from '@/constants/env'
import { generateUUID } from '@/utils'
import { cn } from '@/utils/clsx'

import ChatSidebarContent from './Sidebar/ChatSidebarContent'

const { useBreakpoint } = Grid

interface ChatLayoutProps extends PropsWithChildren {
  adminChatbot?: boolean
}

const ChatLayout: React.FC<ChatLayoutProps> = ({ adminChatbot, children }) => {
  const { theme } = useThemeState()
  const screens = useBreakpoint()
  const [searchParams] = useSearchParams()
  const [firstLoad, setFirstLoad] = useState(true)
  const { open, close, setSelectedConversation, selectedConversation, show } =
    useDrawerState()
  const { user } = useAuth()
  const { t } = useTranslation()
  const { userTourRef4 } = useTourState()

  useEffect(() => {
    if (searchParams && firstLoad) {
      setSelectedConversation(searchParams[1])
      setFirstLoad(false)
    }
  }, [searchParams])

  useEffect(() => {
    if (!selectedConversation) {
      setSelectedConversation(generateUUID())
    }
  }, [])

  if (!user) {
    return (
      <div className='flex h-screen flex-col'>
        <div className='absolute z-10 flex w-full items-center justify-between p-3'>
          <Link href='/' className='flex items-center gap-3'>
            <Image
              src={companyLogo}
              alt='company logo'
              className={cn('cursor-pointer', logoIndexClx)}
            />
            {showIndexHeaderText && (
              <div className='hidden flex-col text-left sm:flex'>
                <span className='text-xl font-bold text-on-surface dark:text-dark-on-surface'>
                  {COMPANY}
                </span>
                <div className='gap-1 text-sm text-on-surface/60 dark:text-dark-on-surface/60'>
                  {t('index-header')}
                </div>
              </div>
            )}
          </Link>
          <div className='flex gap-2'>
            <Link href='/login'>
              <Button>{t('sign-in')}</Button>
            </Link>
            <Link href='/signup'>
              <Button type='primary'>{t('sign-up')}</Button>
            </Link>
          </div>
        </div>
        {children}
      </div>
    )
  }

  return (
    <div className='flex h-screen w-full'>
      {screens.md ? (
        <div className='h-screen w-full max-w-96 bg-surface text-on-surface shadow-md dark:bg-dark-surface dark:text-dark-on-surface'>
          <ChatSidebarContent adminChatbot={adminChatbot} />
        </div>
      ) : (
        <>
          <Drawer
            placement='left'
            closable={false}
            onClose={close}
            open={open}
            styles={{
              body: { backgroundColor: colors[theme].surface, padding: 0 },
            }}
          >
            <ChatSidebarContent adminChatbot={adminChatbot} isDrawer />
          </Drawer>
          <Button
            ref={userTourRef4}
            className='!absolute top-0 z-10 m-2'
            icon={<RiMenu2Line className='size-5' />}
            onClick={show}
          />
        </>
      )}
      <div className='w-full grow overflow-y-auto'>{children}</div>
    </div>
  )
}

export default ChatLayout
