import { RiExpandUpDownLine, RiLogoutBoxRLine } from '@remixicon/react'
import { Avatar, Button, Divider, Popover, Tooltip } from 'antd'
import Link from 'next/link'
import { useTranslation } from 'react-i18next'

import useLoadingState from '@/hooks/context/useLoadingState'
import useAuth from '@/hooks/useAuth'
import useDbSettings from '@/hooks/useDbSettings'

import { API_URL } from '@/constants/env'

import LanguageSwitch from '../Admin/LanguageSwitch'
import ThemeSwitch from '../Admin/ThemeSwitch'
import packageJson from '../../../package.json'

const ProfileBadge: React.FC = () => {
  const { t } = useTranslation()
  const { startLoading } = useLoadingState()
  const { user } = useAuth()
  const { settings } = useDbSettings()

  const signUpCompleted =
    (settings?.requireSubscription && user?.subscribed) ||
    (!settings?.requireSubscription &&
      settings?.useDocumenso &&
      !user?.signed) ||
    (!settings?.requireSubscription && !settings?.useDocumenso)

  if (!user?.email) {
    return null
  }

  return (
    <Popover
      placement='topLeft'
      trigger='click'
      content={
        <div className='flex min-w-[250px] flex-col'>
          <div className='truncate text-base font-semibold'>{user?.name}</div>
          <div className='truncate text-sm'>{user?.email}</div>
          <Divider plain className='my-3' />
          {!signUpCompleted && (
            <>
              <Link href='/signup' onClick={() => startLoading()}>
                <Button className='bg-warning dark:bg-dark-warning'>
                  {t('sign-up-warning-complete')}
                </Button>
              </Link>
              <Divider plain className='my-3' />
            </>
          )}
          <div className='flex flex-col gap-2'>
            <div className='opacity-70'>{t('preferences')}</div>
            <ThemeSwitch className='justify-between' />
            <div className='flex items-center justify-between'>
              <div>{t('language')}</div>
              <LanguageSwitch />
            </div>
          </div>
          <Divider plain className='my-3' />
          <Link href={`${API_URL}/auth/logout`} onClick={() => startLoading()}>
            <Button
              type='text'
              className='flex w-full !justify-start !px-1'
              icon={<RiLogoutBoxRLine className='size-4' />}
            >
              {t('logout')}
            </Button>
          </Link>
          <div className='mt-6 text-xs text-opacity-60'>
            {t('contact-us-terms-privacy-text')}{' '}
            <Link
              href='https://pinnaclemindsinc.com/terms-and-conditions/'
              target='_blank'
              className='text-on-surface hover:text-on-surface/60 dark:text-dark-on-surface hover:dark:text-dark-on-surface/60'
            >
              {t('contact-us-terms-and-conditions')}
            </Link>
            <br />
            {t('contact-us-terms-privacy-connect-text')}{' '}
            <Link
              href='https://pinnaclemindsinc.com/privacy-policy/'
              target='_blank'
              className='text-on-surface hover:text-on-surface/60 dark:text-dark-on-surface hover:dark:text-dark-on-surface/60'
            >
              {t('contact-us-privacy-policy')}
            </Link>
          </div>
          <div className='mt-3 text-xs opacity-60'>
            {t('version')} {packageJson.version}
          </div>
        </div>
      }
    >
      <Tooltip
        open={!signUpCompleted}
        title={t('sign-up-warning-complete')}
        placement='left'
      >
        <Button className='!h-14 w-full !p-2'>
          <div>
            <Avatar className='content-center bg-primary text-lg text-on-primary dark:bg-dark-primary dark:text-dark-on-primary'>
              {user.name.slice(0, 1)}
            </Avatar>
          </div>
          <div className='flex w-full items-center justify-between text-left'>
            <div className='flex flex-col'>
              <div className='text-base'>{user.name}</div>
              <div className='text-sm opacity-70'>{user.email}</div>
            </div>
            <RiExpandUpDownLine className='size-5' />
          </div>
        </Button>
      </Tooltip>
    </Popover>
  )
}

export default ProfileBadge
